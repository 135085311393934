// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'

import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';




const GameOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)

  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay game-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>
      {/*   <button className="close-btn" onClick={toggleOverlay}>X</button> */}
      <CloseButton
            toggleOverlay={toggleOverlay}
        />
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>Play a Game</h2>
          </div>
          <div className="grid-content">

                <GridItem 
                  title="Interactive Game: Star Shades"
                  image="/assets/images/StarShades-poster_img-v4.jpg"
                  url="https://star-shades.waypastbooks.com"
                  target="_self"
                  type="game"
                  orient="horiz"
                />
           
                 <GridItem 
                  title="Interactive Game: Mixed Emotions"
                  image="/assets/images/MixedEmotions-poster_img-v4.jpg"
                  url="https://mixed-emotions.waypastbooks.com"
                  target="_self"
                  type="game"
                  orient="horiz"
                />

                <GridItem 
                  title="Mixed Emotions Card Games"
                  image="/assets/images/WayPastBooks_MixedEmotions_Printable_Card_Deck.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/01/WayPastBooks_MixedEmotions_Printable_Card_Deck.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Fun Card Games"
                  image="/assets/images/waypastfun-cards-poster.jpg"
                  url="/assets/images/pdf/cards-v6.pdf"
                  target="_blank"
                />
                 <GridItem 
                  title="Interactive Game: Draw Your Own Dogs like Yaz & Debby from Way Past Jealous!"
                  image="/assets/images/drawdogs-600-v2.gif"
                  url="/game/drawdogs"
                  target="_self"
                  orient="horiz"
                />
            
          </div>
        </div>
      </div>

  );
};

export default GameOverlay;